.logo-container {
    position: fixed;
    top: 0.1vh;
    right: 3vw;
    display: flex; /* This will align both images in a row */
    align-items: center; /* Center the logos vertically */

}

.linkedin-logo, .github-logo {
    height: auto;
    display: block; /* Removes extra space below the image */
    margin-right: 2vw; /* Adds space between the logos */

}

.linkedin-logo {
    width: 65px; /* Adjust size as needed */
}

.github-logo {
    width: 2.1875rem; /* Adjust size as needed */
}

.resume-logo{
    width: 1.8vw;
    max-width: 40px;
    padding-top: 0.5vw;

}


@media (min-width: 1025px) and (max-width: 1440px) { /* Adjust range as needed */
    .resume-logo {
        width: 30px; /* Adjust size for laptops */
        max-width: 40px;
        padding-top: 0.5vw;
    }
}


@media (max-width: 486px) {

    .logo-container {
        position: fixed;
        top: 1vh;
        right: 5vw;
        display: flex; /* This will align both images in a row */
        align-items: center; /* Center the logos vertically */
        z-index: 100; 



    }

    .linkedin-logo, .github-logo {
        height: auto;
        display: block; /* Removes extra space below the image */
        margin-right: 15px; /* Adds space between the logos */
    
    }



    .linkedin-logo {
        width: 35px; /* Adjust size as needed */
        margin-left: 10px; /* Space from the left edge */
    }
    .github-logo {

        width: 20px
    }
    .resume-logo{
        width: 4.5vw;
        max-width: 40px;
        padding-top: 2.5vw;
    
    }

}

@media (min-width: 487px) and (max-width: 1024px) {

    .logo-container {
        position: fixed;
        top: 1vh;
        right: 5vw;
        display: flex; /* This will align both images in a row */
        align-items: center; /* Center the logos vertically */
        z-index: 100; 



    }

    .linkedin-logo, .github-logo {
        height: auto;
        display: block; /* Removes extra space below the image */
        margin-right: 15px; /* Adds space between the logos */
    
    }


    .github-logo {

        width: 20px
    }

    .resume-logo{
        width: 25px;
        max-width: 40px;
        padding-top: 0.8vw;
    
    }

    .linkedin-logo {
        width: 35px; /* Adjust size as needed */
        margin-left: 10px; /* Space from the left edge */
    }


}