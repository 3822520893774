body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(56, 51, 51);
    

}





::-webkit-scrollbar {
    width: 4px;
}


#project-section {
    padding-bottom: 32vh;
}


#project-section h1 {
    color: #ffffff;
    font-size: 40px;
    font-family: Cambria, serif, ;
    text-align: center;
    bottom: 100vh;
    padding-top: 5vh;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr)); /* Responsive grid columns */
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.project-info li {
    padding: 0.3vw; /* Add padding specifically to the <li> elements */
}

.project-card {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Adjusted shadow position */
    width: 100%; /* This will make the card take full width of the column */
    max-width: 35vw; /* Prevent the card from exceeding the image width */
    display: flex;
    justify-content: center; /* Centers children horizontally */
    align-items: center; /* Centers children vertically */
    flex-direction: column; /* Ensures the image and text are stacked vertically */
    text-align: center; /* Centers the text content */
    background-color: rgba(250, 238, 238, 0); /* Ensure shadow visibility */
}

.project-card:hover {
    transform: scale(1); /* Slightly larger scale on hover */
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3); /* More prominent shadow on hover */
}

.project-image {
    width: 90%;
    height: auto;
    justify-content: center;
    transition: transform 0.3s ease;
    z-index: 1;
    margin: 0 auto;
}


.project-info {
    width: 100%;
    background: rgba(0, 0, 0, 0.75); /* Dark overlay for readability */
    color: white;
    overflow-y: auto; /* Ensure vertical scrolling */
    max-height: 30vh; /* Limit the height to enable scrolling */
    text-align: left; /* Ensure text aligns to the left */
    font-size: 13px;
}

.slick-prev {
    position: absolute;
    z-index: 1;
    margin-left: 15vw;

} 

.slick-next {
    
    z-index: 1;
    margin-right: 15vw;
    z-index: 1;

}


.carousel-container {
    width: 100%;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Tablet devices (up to 1024px) */
@media (min-width: 787px) and (max-width: 1025px) {
    .project-grid {
        grid-template-columns: repeat(auto-fill, minmax(50vw, 1fr)); /* Adjust grid column size for tablet */
        gap: 2vw; /* Smaller gap */
    }

    .project-card {
        max-width: 80vw; /* Limit card width */
        margin: 0 auto; /* Center the card horizontally */
    }

    
    .project-image {
        max-width: 100%;
        height: auto;
    }

    .carousel-container {
        padding: 0 2vw; /* Add padding to make sure content doesn't touch the edges */
    }

    #project-title h1 {
        font-size: 7vw; /* Adjusted for tablet */
    }
}

/* Small tablets and large phones (401px to 786px) */
@media (min-width: 401px) and (max-width: 786px) {
    .project-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /* Center the items horizontally */
        padding: 1vh 1vw; /* Adjust padding */
        gap: 2vw; /* Smaller gap */
    }

    .project-card {
        max-width: 65vw;
        margin: 0 auto; /* Center the card horizontally */
    }

    .slick-prev {
        position: absolute;
        z-index: 1;
        left: 1vw;
    
    } 
    
    .slick-next {
        
        z-index: 1;
        right: 1vw;
    
    }

    .project-image {
        max-width: 100%;
        height: auto;
    }

    .project-info {
        padding: 2vw;
    }

    .project-card ul {
        padding-left: 0;
        list-style-type: none;
    }

    .project-card ul li {
        margin-bottom: 1vw;
    }

    #project-section h1 {
        font-size: 20px;
        padding-top: 9vh;
    }

    #project-section {
        padding-bottom: 35vh;
    }
}

/* Mobile devices (up to 400px) */
@media (max-width: 400px) {
    .project-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project-card {
        max-width: 60vw; /* Full width to use the available space */
    }



    .project-image {
        width: 100%;
        height: auto;
    }

    .project-info {
        font-size: 13px;
    }

    .project-card ul {
        padding-left: 2vw;
    }

    .project-card ul li {
        margin-bottom: 1vw;
    }

    #project-section h1 {
        font-size: 25px;
    }

    #project-section {
        padding-bottom: 32vh;
    }

    .slick-prev {
        
        z-index: 1;
        margin-left: 35px;
    
    } 
    
    .slick-next {
        
        z-index: 1;
        margin-right: 35px;
    
    }

    
}
