.skills-section {
    text-align: center;
    width: 100%;
  }
  
  .skills-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-family: 'Google Sans', monospace;
    color: rgb(243, 246, 248);
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: 15px;
    justify-items: center;
  }
  
  .skill-card {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s, opacity 0.3s;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
  }
  
  .skill-card:hover {
    transform: scale(1.05);
    border-color: #ff6347; /* Change this color as needed */
  }
  
  .skill-card .icon {
    font-size: 1.75rem;
    color: #ff6347; /* Change this color as needed */
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .skill-card h3 {
    margin: 0;
    font-size: 0.75rem;
    font-weight: bold;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 2px 5px;
    white-space: nowrap;
  }
  
  .skill-card:hover .icon {
    transform: translateY(-5px); /* Move the icon up slightly */
    opacity: 1;
  }
  
  .skill-card:hover h3 {
    opacity: 1;
    font-size: 0.75rem;
  }
  
  /* Media queries */
  @media (max-width: 480px) {
    .skills-section h2 {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }
  
    .skill-card {
      width: 60px;
      height: 60px;
    }
  
    .skill-card .icon {
      font-size: 1.5rem;
    }
  
    .skill-card h3 {
      font-size: 0.7rem;
    }
  
    .skills-grid {
      gap: 10px;
    }
  }
  
  @media (min-width: 481px) and (max-width: 780px) {
    .skills-section h2 {
      font-size: 2rem;
      margin-bottom: 30px;
    }
  
    .skill-card {
      width: 70px;
      height: 70px;
    }
  
    .skill-card .icon {
      font-size: 1.75rem;
    }
  
    .skill-card h3 {
      font-size: 1rem;
    }
  
    .skills-section {
      padding-left: 0;
      padding-right: 0%;
    }
  
    .skill-card:hover h3 {
      opacity: 1;
      font-size: 12px;
    }
  }
  
  @media (min-width: 781px) and (max-width: 1200px) {
    .skills-section h2 {
      font-size: 2.5rem;
      margin-bottom: 40px;
    }
  
    .skill-card {
      width: 80px;
      height: 80px;
    }
  
    .skill-card .icon {
      font-size: 1.75rem;
    }
  
    .skill-card h3 {
      font-size: 1.1rem;
    }
  
    .skills-section {
      padding-left: 20vw;
      padding-right: 20vw;
    }
  
    .skill-card:hover h3 {
      opacity: 1;
      font-size: 16px;
    }
  }
  