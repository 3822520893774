
.experienceContainer h1 {
    font-size: 40px;
    color: rgb(255, 255, 255);
    font-family: 'Google Sans', monospace;
    text-align: center;
}

.experienceSection {
    padding-bottom: 25vh;
}



.experienceCard {
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Centers children horizontally */
    justify-content: center; /* Centers children vertically (if needed) */
    width: 100%; /* Use full width of the container */
    max-width: 800px; /* Set a maximum width to avoid being too wide */
    margin: 20px auto; /* Center the card horizontally with auto margins */
    border: 1px solid rgba(181, 175, 240, 0.904);
    box-shadow: 2px 2px 10px rgba(181, 175, 240, 0.904);
    border-radius: 10px;
    padding: 20px; /* Add padding around the content */
    box-sizing: border-box; /* Includes padding and border in the width */
    bottom: -20px;
    background-color: black;
    height: 105vh; /* Make the card shorter */
    overflow: hidden; /* Ensure content does not overflow */
}


.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack image vertically */
    border-radius: 100px;
    width: 100%; /* Ensure the container takes full width */
}

.experienceImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 5px;
    max-width: 100%; /* Ensure the image doesn't exceed the container's width */
    border-radius: 10px;
}

.content {
    z-index: 10;
    color: rgb(255, 240, 240);
    flex: 3;
    font-size: 18px;
    font-family: Cambria, serif;
    text-align: align ; /* Center the text */
    min-height: 70%;
    
}


.content ul li {
    text-align: left;
}




.scrollContent {
    height: 50vh; /* Adjust this height as needed */
    overflow-y: auto;
    padding-right: 10px; /* Adjust for scrollbar */
}

.scrollContent::-webkit-scrollbar {
    width: 6px;
}

.scrollContent::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.scrollContent::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Tablet devices (up to 768px) */
@media  (max-width: 1024px) {
    .experienceCard {
        max-width: 500px; /* Make the card use more space */
        margin: 5px auto; /* Center the card and reduce margin */
    }

    .experienceImage {
        width: 80px; /* Smaller on tablets */
        height: 80px;
    }

    .content, .imageContainer {
        flex-direction: column; /* Adjust layout */
        justify-content: center; /* Center content */
    
    }

    .content {
        order: 2; /* Reorder if necessary */
    }

    .experienceContainer h1 {
        font-size: 25px;
        color: rgb(255, 255, 255);
        font-family: 'Google Sans', monospace;
        text-align: center;
        margin-top: 5vw;
    }

    .slick-prev {
        margin-left: 5vw;
        position: absolute;
        z-index: 100;
    }
    
    .slick-next {
        margin-right: 10vw;
        position: absolute;
        z-index: 100;
    }
    .scrollContent {
        height: 50vh; /* Adjust this height as needed */
        overflow-y: auto;
        padding-right: 10px; /* Adjust for scrollbar */
    }
}

/* Mobile devices (up to 480px) */
@media (min-width: 375px) and (max-width: 480px) {
    .experienceCard {
        flex-direction: column; /* Vertical layout for smaller screens */
        align-items: center; /* Align items vertically */
        padding-bottom: 1vh; /* Ensure padding is sufficient */
        max-width: 275px;
        top: -1vh;
        height: 400px;
    }

    .imageContainer {
        width: 100%; /* Ensure the container takes full width */
        height: auto; /* Adjust height automatically */
        flex: 0 1 auto; /* Do not grow or shrink */
    }

    .experienceImage {
        width: 50px; /* Much smaller images */
        height: 50px;
        max-width: 100%; /* Ensure the image doesn't exceed the container's width */
    }

    .content {
        flex: 1; /* Take available space */
        font-size: 10px; /* Less padding */
        padding-bottom: 1vh;
        
    }

    .content ul li {
        padding-bottom: 1vw;
    }

    .period {
        font-size: 6px; /* Smaller text */
    }

    .slick-prev {
        margin-left: 40px;
        position: absolute;
        z-index: 100;
    }
    
    .slick-next {
        margin-right: 40px;
        position: absolute;
        z-index: 100;
    }

    .scrollContent {
        height: 40vh; /* Adjust this height as needed */
        overflow-y: auto;
        padding-right: 10px; /* Adjust for scrollbar */
    }



    .scrollContent {
        height: 25vh; /* Adjust this height as needed */
        overflow-y: auto;
        padding-right: 10px; /* Adjust for scrollbar */
    }


    .experienceContainer h1 {
        font-size: 25px;
        color: rgb(255, 255, 255);
        font-family: 'Google Sans', monospace;
        text-align: center;
        padding-top: 1vh;
    }

    .experienceContainer {
        padding-bottom: 25vh;
    };


}
