body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

.about-skills-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 20px;
  margin: 20px auto;
  max-width: 1200px;
}

.about-content {
  flex: 2;
  padding-right: 20px;
  margin-bottom: 10vw;
  margin-top: 3vw;
}

.skills-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #f5eae8; /* Change this color as needed */
  text-align: left; /* Align text to the left */
  margin-left: 0; /* Align with the text */
}

.about-text {
  max-width: 600px;
  text-align: left;
  font-size: 1.18rem;
  line-height: 1.8;
  color: #ccc;
}

.about-text p {
  margin-bottom: 20px;
}

.skills-section {
  text-align: center;
  width: 100%;
  padding-top: 3.9vw;
}

.skills-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Google Sans', monospace;
  color: rgb(243, 246, 248);
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 15px;
  justify-items: center;
}

.skill-card {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s, opacity 0.3s;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}

.skill-card:hover {
  transform: scale(1.05);
  border-color: #ff6347; /* Change this color as needed */
}

.skill-card .icon {
  font-size: 1.75rem;
  color: #ff6347; /* Change this color as needed */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.skill-card h3 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 2px 5px;
  white-space: nowrap;
}

.skill-card:hover .icon {
  transform: translateY(-5px); /* Move the icon up slightly */
  opacity: 1;
}

.skill-card:hover h3 {
  opacity: 1;
  font-size: 0.75rem;
}

/* Media queries */
@media (max-width: 768px) {
  .about-skills-section {
    flex-direction: column;
    align-items: center;
  }

  .about-content, .skills-content {
    padding: 0;
    width: 100%;
  }

  .about-content {
    padding-bottom: 40px;
  }

  #about-section h1 {
    font-size: 2rem;
    text-align: center; /* Center text on smaller screens */
  }

  .about-text {
    font-size: 1rem;
    text-align: center; /* Center text on smaller screens */
  }

  .skills-section h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .skill-card {
    width: 70px;
    height: 70px;
  }

  .skill-card .icon {
    font-size: 1.5rem;
  }

  .skill-card h3 {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  #about-section h1 {
    font-size: 1.5rem;
    text-align: center; /* Center text on smaller screens */
  }

  .about-text {
    font-size: 0.9rem;
    text-align: center; /* Center text on smaller screens */
  }

  .skills-section h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .skill-card {
    width: 60px;
    height: 60px;
  }

  .skill-card .icon {
    font-size: 1.5rem;
  }

  .skill-card h3 {
    font-size: 0.7rem;
  }

  .skills-grid {
    gap: 10px;
  }
}

@media (min-width: 481px) and (max-width: 780px) {
  .skills-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .skill-card {
    width: 70px;
    height: 70px;
  }

  .skill-card .icon {
    font-size: 1.75rem;
  }

  .skill-card h3 {
    font-size: 1rem;
  }

  .skills-section {
    padding-left: 0;
    padding-right: 0%;
  }

  .skill-card:hover h3 {
    opacity: 1;
    font-size: 12px;
  }
}

@media (min-width: 781px) and (max-width: 1200px) {
  .skills-section h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }

  .skill-card {
    width: 80px;
    height: 80px;
  }

  .skill-card .icon {
    font-size: 1.75rem;
  }

  .skill-card h3 {
    font-size: 1.1rem;
  }

  .skills-section {
    padding-left: 20vw;
    padding-right: 20vw;
  }

  .skill-card:hover h3 {
    opacity: 1;
    font-size: 16px;
  }
}
