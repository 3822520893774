.intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent; /* Ensure background is transparent */
    color: #ffffff; /* White color for visibility over dark background */
    font-family: 'Google Sans ', monospace;
    position: relative; /* Needed for z-index to take effect */
    z-index: 2; /* Higher than the background */
    padding-top: 10vh; /* Use vh for consistent vertical spacing */
    margin-top: 0; /* Remove negative margin */
    padding-bottom: 19vw;
}

.intro-container h1 {
    font-size: 2vw; /* Use vw for responsive font size */
    color: rgba(181, 175, 240, 0.349);
    margin-bottom: 2vh; /* Use vh for consistent spacing */
}


.my-image {
    width: 23vw; /* Use vw for responsive width */
    height: auto;
}

#navsec {
    justify-content: center;
}

/* Tablet devices (up to 1024px) */
@media (min-width: 787px) and (max-width: 1024px) {
    .intro-container {
        padding-top: 10vh; /* Smaller margin for tablets */
    }

    .intro-container h1 {
        font-size: 2vw; /* Adapt font size to be proportional to smaller screens */
    }



    .my-image {
        width: 35vw; /* Adjust width for smaller screens */
        padding-top: 2vh; /* Adjust the top margin for proper spacing */
    }

}

/* Mobile devices (up to 480px) */
@media (max-width: 480px) {
    .intro-container {
        padding-top: 5vh; /* Smaller margin for mobiles */
    }

    .intro-container h1 {
        font-size: 3vw; /* Larger font size for better readability */
        padding-bottom: 5vh; /* Less margin to keep things tight */
    }

    .intro-container p {
        font-size: 3vw; /* Smaller font size for mobiles */
        width: 70%;
        
    }

    .my-image {
        width: 50vw; /* Adjust width for mobiles */
        padding-top: 2vh; /* Adjust the top margin for proper spacing */
    }

    #navsec {
      top:-500px;
    }

  


}

/* Small tablets and large phones (481px to 786px) */
@media (min-width: 481px) and (max-width: 786px) {

    .intro-container h1 {
        font-size: 3vw;
    }
}
