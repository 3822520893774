#binary-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  justify-content: center; /* Center digits horizontally */
  z-index: -1;
  
}
html, body {
  height: 100%; 
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.binary-digit {
  width: 1.5vw; /* Start with a conservative size based on viewport width */
  height: 4.5vh; /* Match the height relative to viewport height */
  font-size: 1.2vw; /* Adjust font size dynamically based on screen width */
  animation: moveUpDown 10s linear infinite;
  position: absolute;
  color:rgba(76, 74, 90, 0.904) ;
}

@keyframes moveUpDown {
  0%, 100% {
      transform: translateY(-100vh); /* Ensure movement is relative to screen height */
  }
  50% {
      transform: translateY(100vh);
  }
}

@keyframes flow {
  from { top: -12vw; }
  to { top: 100vh; } /* Adjust based on the height of your view */
}
/* General adjustments for tablets */
@media (max-width: 768px) {
  .binary-digit {
    width: 15px; /* Reduce width to fit more digits or prevent overlap */
    height: 6vw; /* Adjust height proportionally */
    font-size: 15px; /* Smaller font size for better appearance */
  }

  @keyframes moveUpDown {
    0%, 100% {
        transform: translateY(-50vh); /* Less travel distance for the animation */
    }
    50% {
        transform: translateY(50vh);
    }
  }
}

/* Specific adjustments for mobile phones */
@media (max-width: 430px) {
  .binary-digit {
    width: 3vw; /* Even smaller width */
    height: 4vw; /* Smaller height to maintain the aspect ratio */
    font-size: 8px; /* Further reduce the font size */
  }

  @keyframes moveUpDown {
    0%, 100% {
        transform: translateY(-30vh); /* Less vertical movement for smaller screens */
    }
    50% {
        transform: translateY(30vh);
    }
  }

  /* Adjusting flow animation for smaller screens to ensure it completes within the viewport */
  @keyframes flow {
    from { top: -6vw; }
    to { top: 100vh; }
  }
}

